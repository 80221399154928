<template>
  <b-container v-if="loaded" class="mt-2">
    <masthead :show-search="false">
      <b-button variant="link" :class="$mq != 'sm' ? 'btn-shadow mr-2' : ''" @click="goBack">
        <feather-icon type="chevron-left" />
        Back
      </b-button>
    </masthead>
    <h2>Filing Methods</h2>
    <div v-if="filingMethods" class="outer-container">
      <b-card
        v-for="methodsWithType in filingMethods"
        :key="methodsWithType.key"
        no-body
        class="filing-method-containers"
        :header="startCase(methodsWithType[0].type)"
        style="max-width: 90rem;"
      >
        <b-list-group
          v-for="method in methodsWithType"
          :key="method.id"
          flush
          class="filing-method-body"
        >
          <b-list-group-item>
            {{ method.name }}
            {{ formatEstimateForDisplay(method.filedIn.days, method.filedIn.hours) }}
            {{ formatCostForDisplay(method.cost) }}
            <b-button
              variant="link"
              class="select-button"
              @click="methodSelected(method.id)"
            >
              Select
            </b-button>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </div>
    <div v-else>
      <br>
      <span>No Filing Methods Available</span>
    </div>
  </b-container>
</template>

<script>
import Masthead from '../../components/shared/Masthead'
import FeatherIcon from '../../components/shared/FeatherIcon'
import _ from 'lodash'

export default {
  name: 'FilingMethodSelection',
  components: { FeatherIcon, Masthead },
  data: function() {
    return {
      loaded: false,
      companyId: this.$route.params.companyId,
      jurisdictionId: this.$route.params.jurisdictionId,
      filingId: this.$route.params.filingId,
      filingMethods: [],
    }
  },
  mounted: async function() {
    const response = await this.$store.dispatch('filingMethods/fetchFilingMethods', {
      params: {
        filingId: this.filingId,
      },
    })
    this.filingMethods = response.data
    this.filingMethods = _.groupBy(this.filingMethods, function(record) {
      return record.type
    })
    this.loaded = true
  },
  methods: {
    methodSelected: function(methodId) {
      this.$router.push({
        name: 'resourceSelection',
        params: {
          companyId: this.companyId,
          jurisdictionId: this.jurisdictionId,
          filingId: this.filingId,
          filingMethodId: methodId,
        },
      })
    },
    goBack: function() {
      this.$router.go(-1)
    },
    formatEstimateForDisplay: function(days, hours) {
      let format = ''
      if (days === 0 || !days) {
        format = hours + ' hours'
      } else if (hours === 0 || !hours) {
        format = days + ' days'
      } else {
        format = days + ' days and ' + hours + ' hours'
      }
      return format
    },
    formatCostForDisplay: function(cost) {
      return ' - $' + cost
    },
    startCase: function(string) {
      return _.startCase(string)
    },
  },
}
</script>

<style lang="scss" scoped>
.select-button {
  float: right;
  border-top: 0;
  padding-top: 0;
}

.filing-method-containers {
  margin: 1.5em 0;
}
</style>
