var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "b-container",
        { staticClass: "mt-2" },
        [
          _c(
            "masthead",
            { attrs: { "show-search": false } },
            [
              _c(
                "b-button",
                {
                  class: _vm.$mq != "sm" ? "btn-shadow mr-2" : "",
                  attrs: { variant: "link" },
                  on: { click: _vm.goBack },
                },
                [
                  _c("feather-icon", { attrs: { type: "chevron-left" } }),
                  _vm._v("\n      Back\n    "),
                ],
                1
              ),
            ],
            1
          ),
          _c("h2", [_vm._v("Filing Methods")]),
          _vm.filingMethods
            ? _c(
                "div",
                { staticClass: "outer-container" },
                _vm._l(_vm.filingMethods, function (methodsWithType) {
                  return _c(
                    "b-card",
                    {
                      key: methodsWithType.key,
                      staticClass: "filing-method-containers",
                      staticStyle: { "max-width": "90rem" },
                      attrs: {
                        "no-body": "",
                        header: _vm.startCase(methodsWithType[0].type),
                      },
                    },
                    _vm._l(methodsWithType, function (method) {
                      return _c(
                        "b-list-group",
                        {
                          key: method.id,
                          staticClass: "filing-method-body",
                          attrs: { flush: "" },
                        },
                        [
                          _c(
                            "b-list-group-item",
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(method.name) +
                                  "\n          " +
                                  _vm._s(
                                    _vm.formatEstimateForDisplay(
                                      method.filedIn.days,
                                      method.filedIn.hours
                                    )
                                  ) +
                                  "\n          " +
                                  _vm._s(
                                    _vm.formatCostForDisplay(method.cost)
                                  ) +
                                  "\n          "
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "select-button",
                                  attrs: { variant: "link" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.methodSelected(method.id)
                                    },
                                  },
                                },
                                [_vm._v("\n            Select\n          ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                }),
                1
              )
            : _c("div", [
                _c("br"),
                _c("span", [_vm._v("No Filing Methods Available")]),
              ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }